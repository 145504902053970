<template src="./template.html"></template>

<script>
import EditAuth from "@/components/CustomerAuth/EditDialog"
export default {
  name: "CustomerAuth",
  components: {
    EditAuth
  },
  data() {
    return {
      customer_auth: null,
      customers: [],
      users: [],
      headers: [
        {
          text: '客戶編號',
          align: 'start',
          value: 'CustomerID',
          width: 130
        },
        {
          text: '客戶名稱',
          align: 'start',
          value: 'Name',
        },
        {
          text: '品牌顧問',
          align: 'start',
          value: 'Admin',
        },
        { text: '建立日期', value: 'NewTime' },
        { text: '動作', value: 'actions', sortable: false },
      ],
      footerprops: {
        'items-per-page-text': '每頁顯示',
      },
    }
  },
  methods: {
    OpenEdit(item) {
      this.$refs.EditAuth.Open(item.Admin, item)
    },
    GetAccount(id) {
      let user = this.users.filter(item => item.AdminID == id)
      return user.length > 0 ? user[0].Nickname : ""
    },
    async UpdateData() {
      this.loading = true
      let response = await this.SendData(
        "/admin/customer/auth/update", "post", {
        Data: {
          CustomerID: 15,
          AdminID: [4, 6]
        }
      })
      this.loading = false
      if (response != "error") {
        console.log(response)
      }
    },
    async LoadData() {
      this.loading = true
      let response = await this.SendData("/admin/customer/auth/get", "post", { Data: {} })
      this.loading = false
      if (response != "error") {

        this.customer_auth = response
        this.LoadCustomerData()
      }
    },
    async LoadCustomerData() {
      this.loading = true
      let response = await this.GetData("/admin/customer")
      this.loading = false
      if (response != "error") {

        let Data = response
        Data.forEach(item => {
          Object.keys(this.customer_auth).forEach(customer => {
            item.CustomerID == customer ? item.Admin = this.customer_auth[customer] : ""
          })
        });
        this.customers = []
        this.customers = Data
      }
    },
    async LoadAccountData() {
      this.loading = true
      let response = await this.GetData("/admin/admin")
      this.loading = false
      if (response != "error") {

        this.users = response
      }
    },
  },
  async created() {
    await this.LoadAccountData()
    this.LoadData()
  }
}
</script>