<template src="./template.html"></template>

<script>
export default {
  name: "CustomerEdit",
  props: {
    users: {
      require: true,
      type: Array
    }
  },
  data() {
    return {
      dialog: false,
      valid: false,
      editedItem: {
        Name: "",
        CustomerID: "",
        AdminID: []
      },
      defaultItem: {
        Name: "",
        CustomerID: "",
        AdminID: []
      },
      rules: {
        required: value => { return (value == '') ? '此欄位不得留空' : true },
      },
    }
  },
  methods: {
    Open(admins, customer) {
      this.editedItem.Name = customer.Name
      this.editedItem.CustomerID = customer.CustomerID
      this.editedItem.AdminID = admins
      this.dialog = true
      this.valid = true
    },
    Close() {
      this.editedItem = Object.assign({}, this.defaultItem)
      this.dialog = false
      this.valid = true
    },
    Validate() {
      this.$refs.CustomerForm.validate()
      setTimeout(() => {
        this.valid ? this.CreateCustomer() : ""
      }, 100);
    },
    async CreateCustomer() {
      this.$store.commit("SetLoading", true)
      let response = await this.SendData("/admin/customer/auth/update", "post", { Data: this.editedItem })
      this.$store.commit("SetLoading", false)
      if (response != "error") {
        this.Close()
        this.$emit("load-data")
        this.$store.commit("SetSnackBar", { status: true, content: "已成功更新客戶權限" })
      }
    }
  },
  created() { },
}
</script>